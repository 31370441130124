import React, { useState, useEffect } from "react";
import ESpics from "../components/ESpics";
import { Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import NA from "../IMG/NAN.webp";
import "../css/Essentials.css";
import Footer from "../components/Footer";
import Navbar from "../components/Nabarall";

function Essentials() {
  const navigate = useNavigate();

  // Estado para manejar la imagen principal
  // eslint-disable-next-line no-unused-vars
  const [imagenPrincipal, setImagenPrincipal] = useState(NA); // Imagen por defecto o inicial

  // Estado para controlar la visibilidad del Navbar
  const [navbarVisible, setNavbarVisible] = useState(true);

  // Guardar la posición previa del scroll
  const [prevScrollPos, setPrevScrollPos] = useState(window.pageYOffset);

  // Escuchar el evento de scroll para mostrar/ocultar el Navbar
  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.pageYOffset;
      const windowHeight = window.innerHeight;
      const scrollThreshold = windowHeight * 0.1; // 10% de la altura de la ventana

      // Si el usuario hace scroll hacia abajo y pasa del 10% del vh, ocultamos el Navbar
      if (
        currentScrollPos > prevScrollPos &&
        currentScrollPos > scrollThreshold
      ) {
        setNavbarVisible(false); // Scroll hacia abajo y más del 10%, ocultar el Navbar
      }
      // Si el usuario hace scroll hacia arriba, mostramos el Navbar
      else if (currentScrollPos < prevScrollPos) {
        setNavbarVisible(true); // Scroll hacia arriba, mostrar el Navbar
      }

      // Actualizar la posición previa del scroll
      setPrevScrollPos(currentScrollPos);
    };

    window.addEventListener("scroll", handleScroll);

    // Limpiar el evento cuando el componente se desmonte
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [prevScrollPos]);

  // Función para manejar la navegación al hacer clic en la imagen
  // eslint-disable-next-line no-unused-vars
  const handleNavigation = (path) => {
    navigate(path);
  };

  // Función para cambiar la imagen principal al hacer clic en una miniatura
  const handleImageClick = (imageSrc) => {
    setImagenPrincipal(imageSrc); // Actualiza la imagen principal
  };

  return (
    <div>
      {/* Cambiar la clase del Navbar dependiendo del estado */}
      <div
        className={
          navbarVisible ? "navbar navbar-visible" : "navbar navbar-hidden"
        }
      >
        <Navbar />
      </div>

      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        width="100%"
        height="100%"
      >
        <Box
          className="piclist"
          width={{
            xs: "100%",
            md: "80%",
          }}
        >
          {/* Aquí pasamos la función para manejar el clic en una miniatura */}
          <ESpics onImageClick={handleImageClick} style={{ width: "100%" }} />
        </Box>
      </Box>
      <Box sx={{ mt: "10rem" }}>
        <Footer />
      </Box>
    </div>
  );
}

export default Essentials;
