import React from "react";
import { Grid, Box, Typography, useMediaQuery } from "@mui/material";

const ProductItem = ({ producto }) => {
  const isMobile = useMediaQuery("(max-width: 600px)");

  return (
    <Grid item xs={12} sm={6} md={3}>
      <Box
        sx={{
          cursor: "pointer",
          "&:hover": { opacity: 0.8 },
        }}
      >
        {/* Muestra la primera imagen */}
        <img
          src={producto.imagenes[0]} // Mostramos la primera imagen del array
          alt={producto.nombre}
          loading="lazy"
          style={{
            width: "100%",
            height: isMobile ? "200px" : "300px",
            objectFit: "cover",
          }}
        />
        <Typography variant={isMobile ? "body1" : "h6"} sx={{ mt: 1 }}>
          {producto.nombre}
        </Typography>
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{ fontFamily: "Arial, sans-serif" }}
        >
          {producto.precio} MXN
        </Typography>
      </Box>
    </Grid>
  );
};

export default ProductItem;
