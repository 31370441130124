import React from "react";
import ESpics from "../components/ESpics";
import { Box } from "@mui/material"; // Asegúrate de importar AppBar y Toolbar si los usas
import useMediaQuery from "@mui/material/useMediaQuery";
import { useNavigate } from "react-router-dom";
import NA from "../IMG/NAN.webp";
import "../css/Essentials.css";
import Sidebar from "../components/Sidebar";
import Footer from "../components/Footer";

function Essentials() {
  const isMobile = useMediaQuery("(max-width: 600px)");
  const navigate = useNavigate();

  // Función para manejar la navegación al hacer clic en la imagen
  const handleNavigation = (path) => {
    navigate(path);
  };

  return (
    <div>
      <Box
        sx={{
          position: isMobile ? "absolute" : "fixed",
          top: isMobile ? "26px" : "30px", // Ajuste de la posición superior
          left: isMobile ? "-7px" : "-80px", // Ajuste de la posición izquierda
          zIndex: 2, // Por encima de la imagen
        }}
      >
        <Sidebar
          iconSize={isMobile ? "large" : "extra-large"} // Ajuste del tamaño del icono
        />
      </Box>

      <Box
        sx={{
          position: "fixed", // Cambia a fixed para que la imagen no se mueva
          top: isMobile ? "1px" : "0px", // Ajusta la posición en móviles para que no quede debajo del AppBar
          left: isMobile ? "40px" : "144px",
          zIndex: 1,
          boxSizing: "border-box",
          width: isMobile ? "300px" : "300px",
        }}
      >
        <Box
          component="img"
          src={NA}
          alt="NA"
          onClick={() => handleNavigation("/home")} // Llama a handleNavigation con la ruta /home
          sx={{
            width: "100%",
            height: "auto",
            objectFit: "contain", // Mantiene la relación de aspecto
            cursor: "pointer", // Cambia el cursor al hacer hover
          }}
        />
      </Box>

      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        width="100%"
        height="100%"
      >
        <Box
          className="piclist"
          width={{
            xs: "100%",
            md: "80%",
          }}
        >
          <ESpics style={{ width: "100%" }} />
        </Box>
      </Box>
      <Box sx={{ mt: "10rem" }}>
        <Footer />
      </Box>
    </div>
  );
}

export default Essentials;
